.ml-025 {
  margin-left: 0.25rem;
}

.ml-05 {
  margin-left: 0.5rem;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-10 {
  margin-left: 10rem;
}

.mr-05{
  margin-right: 0.5rem;
}

.mr-1 {
  margin-right: 1rem;
}

.flex {
  display:flex;
}

.text-right {
  text-align: right;
}

.text-white {
  color:#fff;
}


.text-ukg {
  color:#ff4500;
  font-size: 0.8rem;
}

.small {
  font-size: 0.75rem;
}

.icon-selectable{
  color: #dd4b39;
  cursor: pointer;
}

.icon-disabled{
  color: #808080;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

#demo {
  width: 0px;
  height: 0px;
  visibility: hidden;
}

.btn-close-custom {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
  background: transparent;
}

.row {
  margin-right: 0rem;
}